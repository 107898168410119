<template>
    <div style="padding-top: 0px;" >

        <div class="row justify-center q-pt-sm">
        <!--    <router-link
                :to="{name: 'Preventivi.SelezioneArea'}"
                class="pulsante_singolo"
                v-if="isNuovoPreventivoEnabled"
            > //-->
                <QQButton label="Nuovo preventivo"
                    class="q-mb-sm"
                    color="blue-grey"
                    icon="addchart"
                    size="sm"
                    @click.native="onNuovoPreventivo"
                />
        <!--    </router-link> //-->

            <QQButton class="q-mb-sm"
                label="Aggiorna elenco"
                color="blue-grey"
                icon="mdi-database-refresh-outline"
                size="sm"
                @click.native="aggiornaListaPreventivi"
            />
        </div>
        <div align="center">
            <h5 class="q-mt-sm q-mb-sm">Clicca su un preventivo per attivare le opzioni di gestione</h5>
        </div>
        <hr>
        <div class="text-center justify-center q-pa-md q-gutter-sm fixed-center"
            v-show="!is_loading"
            style="z-index: 1000;">

            <q-spinner-hourglass
                size="4em"
            />

        </div>
<!-- ATTENZIONE: I preventivi in quotazione acquistabili potrebbero avere la consulenza impostata a zero. Per impostare la consulenza, clicca sul preventivo, clicca su acquista, seleziona la consulenza e registra di nuovo il preventivo. -->
        <div class="q-px-md">
            <q-table
                title=""
                wrap-cells
                :data="rows"
                :columns="colonne"
                :filter="filter"
                separator="cell"
                row-key="id"
                :rows-per-page-options="[20,40,60]"
                @row-click="selezionaRecordPreventivi"
                class="my-sticky-header-table no-box-shadow q-mt-none">

                <template v-slot:top-right>
                    <div class="panel-cerca">
                        <q-input dense debounce="300" outlined  v-model="filter" placeholder="Cerca" style="min-width: 350px;">
                            <template v-slot:append>
                                <q-icon name="search" />
                            </template>
                        </q-input>
                    </div>
                </template>

                <template v-slot:header="props">
                    <q-tr :props="props">
                        <q-th
                            v-for="col in props.cols"
                            :key="col.name"
                            :props="props"
                            style="font-size: 14px; border-bottom: 1px SOLID #404040; background-color: #ffcc99; color: #000;">
                            <strong>{{ col.label }}</strong>
                        </q-th>
                    </q-tr>
                </template>

                <template v-slot:body="props">
                    <q-tr :props="props"
                        style="cursor: pointer;"
                        @click.native="selezionaRecordPreventivi(null,props.row)">
                    <!--    <q-td auto-width>
                            {{ props.cols[0].value }}
                        </q-td> //-->
                        <q-td auto-width v-html="props.cols[0].value">
                        </q-td>
                        <q-td auto-width>
                            {{ props.cols[1].value }}
                        </q-td>
                        <q-td auto-width>
                            {{ props.cols[2].value }}
                        </q-td>
                        <q-td auto-width>
                            {{ props.cols[3].value }}
                        </q-td>
                        <q-td auto-width>
                            {{ props.cols[4].value.replaceAll("_"," ") }}
                        </q-td>
                        <q-td auto-width>
                            <div v-for="col in props.cols[5].value.split(',')" :key="col.name" :prop="props" v-html="VisualizzaDatiProdotto(col)" />
                        </q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>

        <br><br><br><br><br>
    </div>
</template>

<script>

// Include la libreria con le funzioni comuni a tutti i componenti
import QQButton from "@/components/QQButton.vue";
//import commonLib from "@/libs/commonLib";
import policy from "@/libs/policy"
import { mapState, mapActions } from "vuex";
import { mapFields } from 'vuex-map-fields'
import store from "@/store";

export default {
    name: "TabellaPreventivi",
    components: {
        QQButton
    },
    data() {
        return {
            tab: "Preventivi",
            splitterModel: 20,
            filter: '',
            persistent: false,
            TipoRecordSelezionato: ""
        }
    },
    methods: {
        onNuovoPreventivo() {
            let area = "professionisti";
            store.commit("formPreventivo/clear");
            store.commit("setArea",area);
            this.vuole_analisi_necessita = true;

            this.step = 2;
            this.$router.push({"path" :"FormPreventivo?area="+area});
        },
        VisualizzaDatiProdotto(value) {
        /*    var elements = value.split(":");

            if (elements[0] !== "TOTALE") {
                return elements[0]+": <strong>"+elements[1]+"</strong>";
            }

            //return "-------------------------------------------<br><div align='center'>----> "+
            return "<hr style='color: #fff;'><div align='center'>----> "+
                elements[0]+": <strong>"+
                elements[1]+"</strong> <----</div>"; */
            return value;
        },
        filterFn(input_value, update, abort) {
            if (input_value.length < 3) {
                abort();
                return;
            }

            //console.log(input_value);
            update(() => {
                this.domanda.options = this.options_domanda.filter((option) => {
                    return option.label.indexOf(input_value) > -1
                });
            });
        },
        ...mapActions({
            fetchElencoPreventivi: "formPreventivo/fetchElencoPreventivi",
            fetchDatiPreventivo: "formPreventivo/fetchDatiPreventivo"
        }),
        async aggiornaListaPreventivi() {
            await this.fetchElencoPreventivi(this.area);
        },
        isPreventivoModificabile() {
            if (policy.PuoModificarePreventiviPropri()) {
                if (parseInt(policy.IdUnitaOperativa()) !== parseInt(this.RecordSelezionato["id_unita_operativa"])) {
                    return false
                }
            }
            if (policy.PuoModificarePreventiviGruppo()) {
                var UnitaOperativeSottostanti = policy.UnitaOperativeSottostanti();
                //if (!UnitaOperativeSottostanti.includes(parseInt(this.RecordSelezionato["id_unita_operativa"]))) {
                if (!UnitaOperativeSottostanti.includes(this.RecordSelezionato["id_unita_operativa"])) {
                    return false;
                }
            }
            var esito = policy.PuoModificarePreventiviDiTutti() ||
                   policy.PuoModificarePreventiviPropri() ||
                   policy.PuoModificarePreventiviGruppo();

            return esito;
        },
        async selezionaRecordPreventivi(event,row) {
            console.log("evento:",event);
           // console.log("row:",row);

            // Verifica se il preventivo risulta rifiutato. Se si, non attiva il menu
            // ma visualizza solo un messaggio
            if (row.stato === "RIFIUTATO") {
                this.$q.dialog({
                    title: 'Attenzione',
                    message: 'Il preventivo selezionato risulta CHIUSO E NON QUOTATO e non puo` essere modificato. '
                });
                return;
            }

            this.TipoRecordSelezionato = "Preventivo";
            this.RecordSelezionato = row;

            this.$store.commit("setArea",this.RecordSelezionato.area);

            if (this.isPreventivoModificabile()) {
                await this.fetchDatiPreventivo(this.RecordSelezionato.numero);
                //await this.fetchElencoPreventivi(this.area);
                this.$router.push({name : "Preventivi.MenuGestionePreventivi"});
            } else {
                this.$q.dialog({
                    title: 'Attenzione',
                    message: 'Il preventivo selezionato non può essere modificato con le tue credenziali di accesso. '
                });
            }
        }
    },
    computed: {

        isNuovoPreventivoEnabled() {
            return policy.PuoCalcolareNuoviPreventivi();
        },
        ...mapState({
            Area: state => state.area,
            area: state => state.area,
            titolo_area: state => state.titolo_area,
            pratiche_fetchate: state => state.gestionePratiche.pratiche,
            colonne: state => state.formPreventivo.colonne,
            rows: state => state.formPreventivo.righe,
            is_loading: state => state.formPreventivo.is_loading,
            have_errors: state => state.formPreventivo.have_errors
        }),
        ...mapFields('formPreventivo', [
            "vuole_analisi_necessita",
            "step"
        ]),
        ...mapFields("gestionePreventivi", {
            RecordSelezionato: "RecordSelezionato",
        }),
    },
    async mounted() {
        //this.is_loading = true;
        //console.log("mounted tabella preve");
        let oJSon = await this.fetchElencoPreventivi("");

        if (this.have_errors) {
            this.$q.dialog({
                title: 'Attenzione',
                message: 'Si è verificato un errore durante l`elaborazione dei dati: '+oJSon.response.status + " - "+oJSon.response.statusText
            });
        }
        this.$store.dispatch("formPreventivo/reset_is_loading");
    },
    async activated() {
        //this.is_loading = true;
        //this.$store.dispatch("formPreventivo/reset_is_loading");
        await this.aggiornaListaPreventivi();
    }
}
</script>

<style scoped lang="scss">

   /* .panel-cerca {
        border: 1px SOLID #000;
        padding-left: 16px;
        padding-right: 16px;
        border-radius: 25px;
        color: #000;
    }*/

    ul {
        margin-top: 0px;
        margin-bottom: 0px;
        list-style: none;
    }

    li:before {
        content: "►";
        display: block;
        float: left;
        width: 1.2em;
        color: #ff0000;
    }

    .q-td {
        vertical-align: text-top;
    }

</style>


